import { ApiClientProps } from "hooks/useApi";

export interface CompleteOnboardingCallProps {
    onboardingCode: string;
    id?: number;
    partnerId?: string;
    posType?: string;
    token?: string;
    apiClient: ApiClientProps;
}

export interface SuccessResponse {
  hasErrors: false;
  message: string;
  requestId: number;
}

interface ErrorResponse {
  status: string;
  timestamp: string;
  message: string;
  debugMessage: string | null;
  validationErrors: any | null;
}

export type Response = SuccessResponse | ErrorResponse;

async function completeOnboardingCall({
  onboardingCode,
  id,
  partnerId,
  posType,
  token,
  apiClient
}: CompleteOnboardingCallProps): Promise<Response> {
  if (!onboardingCode || !id) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for complete onboarding call");
  }

  if (!partnerId) {
    throw new Error("No partnerId provided for complete onboarding call");
  }

  const body = JSON.stringify({
    onboardingCode,
  });

  return await apiClient.post({

    path: `/middleware-admin/partners/${partnerId}/locations/onboarding/complete/${posType}/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });
}

export default completeOnboardingCall;
