import { ApiClientProps } from "hooks/useApi";
import { Location } from "./getLocations";

export interface NewLocation {
  checkMatchEnabled: boolean;
  enabled: boolean;
  locationId: string;
  logWebhook: boolean;
  name: string;
  partnerKey: string;
  partnerVenueId?: string;
  posType: string;
  apiType?: string;
  skipSend: boolean;
  tableStatusEnabled: boolean;
  upservePassword?: string;
  upserveUsername?: string;
}

async function createLocation(
  {
    checkMatchEnabled,
    enabled,
    locationId,
    logWebhook,
    name,
    partnerKey,
    partnerVenueId,
    posType,
    apiType,
    skipSend,
    tableStatusEnabled,
    upservePassword,
    upserveUsername,
  }: NewLocation,
  apiClient: ApiClientProps,
  partnerId?: string,
  token?: string
): Promise<Location[]> {
  if (!locationId || !name || !posType || !partnerKey) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for creating the location");
  }

  if (!partnerId) {
    throw new Error("No partnerId provided for creating the location");
  }

  const body = JSON.stringify({
    checkMatchEnabled,
    enabled,
    locationId,
    logWebhook,
    name,
    partnerKey,
    partnerVenueId,
    posType,
    apiType,
    skipSend,
    tableStatusEnabled,
    upservePassword,
    upserveUsername,
  });

  return await apiClient.post({
    path: `/middleware-admin/partners/${partnerId}/locations`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body,
  });
}

export default createLocation;
