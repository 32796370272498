import { ApiClientProps } from "hooks/useApi";

export interface Location {
  id?: number;
  locationId?: string;
  name?: string | null;
  enabled?: boolean;
  partnerId?: string;
  tableStatusEnabled?: boolean;
  checkMatchEnabled?: boolean;
  partnerVenueId?: string;
  posType: string;
}

async function updateLocation(
  {
    id,
    locationId,
    name,
    enabled,
    tableStatusEnabled,
    checkMatchEnabled,
    posType,
    partnerId,
    partnerVenueId,
  }: Location,
  apiClient: ApiClientProps,
  token?: string
): Promise<Location[]> {
  if (!id || !locationId || !posType || !partnerId) {
    throw new Error(
      "Network failure. Please check your connection and ensure your request includes all required parameters."
    );
  }

  if (!token) {
    throw new Error("No token provided for updating location");
  }

  const body = JSON.stringify({
    locationId,
    name,
    enabled,
    tableStatusEnabled,
    checkMatchEnabled,
    partnerVenueId,
    posType,
  });

  return await apiClient.put({
    path: `/middleware-admin/partners/${partnerId}/locations/${id}`,
    headers: {
      "Content-Type": "application/json",
      partnerKey:
        posType === "omnivore" ? "omnivore-stage" : "resy-prod-sample",
      Authorization: `Bearer ${token}`,
    },
    body,
  });
}

export default updateLocation;
